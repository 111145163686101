import { gql } from '@apollo/client';
import { CustomerCriticalNoteFragment } from 'graphql/types';
import { getAuthorDisplayName } from 'utils/get-author-display-name';
import { formatDateAndTime } from 'utils/misc';
import { Card } from 'components/card';
import { Markdown } from 'components/markdown';
import { EditCriticalNoteModalButton } from './edit-critical-note-modal-button';
import { noteHistoryDocument } from '../history/note-history-modal';
import { NoteHistoryModalButton } from '../history/note-history-modal-button';
import { AddCriticalNoteModalButton } from './add-critical-note-modal-button';
import { RemoveCriticalNoteButton } from './remove-critical-note-button';

export const criticalNoteDocument = gql`
  fragment CriticalNote on Note {
    id
    body
    domain
    author {
      id
      role
      fullName
      clinicianName
    }
    updatedAt

    ...NoteHistory
  }

  ${noteHistoryDocument}
`;

export const customerCriticalNoteDocument = gql`
  fragment CustomerCriticalNote on User {
    id
    criticalNote {
      id
      ...CriticalNote
    }
  }

  ${criticalNoteDocument}
`;

export const CriticalNoteCard: React.FC<{
  customer: CustomerCriticalNoteFragment;
}> = ({ customer }) => {
  const criticalNote = customer.criticalNote;

  if (!criticalNote || !criticalNote.body) {
    return (
      <Card className="px-4 py-5">
        <div className="flex flex-row items-center space-x-4">
          <h3 className="text-lg leading-5 font-medium flex-grow my-auto">
            Critical Note
          </h3>
          {criticalNote && (
            <NoteHistoryModalButton title="Critical Note" note={criticalNote} />
          )}
          <AddCriticalNoteModalButton
            customerId={customer.id}
            noteId={criticalNote?.id}
          />
        </div>
      </Card>
    );
  }

  return (
    <Card className="px-4 py-3 !bg-red-200 border border-red-500 space-y-2 rounded">
      <div className="flex flex-row items-center mb-4 space-x-4">
        <h3 className="text-lg leading-5 font-medium flex-grow my-auto">
          Critical Note
        </h3>
        <NoteHistoryModalButton title="Critical Note" note={criticalNote} />

        <RemoveCriticalNoteButton
          customerId={customer.id}
          note={criticalNote}
        />
        <EditCriticalNoteModalButton
          customerId={customer.id}
          note={criticalNote}
        />
      </div>
      <div className="text-sm leading-5">
        <Markdown src={criticalNote.body} />
      </div>
      <div className="text-sm text-gray-700 italic flex justify-end">
        Last updated by {getAuthorDisplayName(criticalNote.author)}{' '}
        {formatDateAndTime(criticalNote.updatedAt)}
      </div>
    </Card>
  );
};
